import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Logo } from "assets/images";
import { withAuthState } from "components/hoc/auth";
import routes from "constants/routes";
import React, { useEffect } from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  logout: () => void;
}
interface Props {
  logout: () => void;
}

const Nav: React.FC<Props> = (props) => {
  // const api = new OrganizationResource();
  // const [organizationList, setOrganizationList] = useState<any>([]);
  // const { data: organizationList, isLoading: isListLoading } = useQuery(
  //   [
  //     "organizationList",
  //     {
  //       page: 1,
  //       page_size: 1000,
  //     },
  //   ],
  //   async () => {
  //     const queryParams: any = {
  //       page: 1,
  //       page_size: 1000,
  //     };
  //     const response = await api.list(queryParams);

  //     // setOrganizationList(response?.data);
  //     return response?.data?.data;
  //   },
  //   {
  //     cacheTime: 0,
  //     refetchOnWindowFocus: false,
  //   }
  // );
  let navigate = useNavigate();
  const location = useLocation();

  const { user } = useSelector(
    (state: any) => ({
      user: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const { logout } = props;

  const onLogoutClick = async () => {
    try {
      logout();
    } catch (err) {}
  };

  useEffect(() => {
    if (location.pathname === "/") {
      navigate(routes.users.list);
    }
  }, [location, navigate]);

  return (
    <>
      <Box bg={"#44474A"} px={4} className="header--wrap">
        <Flex
          h={20}
          alignItems={"center"}
          justifyContent={"space-between"}
          color={"#fff"}
        >
          <Flex className={"left-content-wrapper"}>
            <HStack spacing={8} alignItems={"center"}>
              <Box>
                <Image
                  src={Logo}
                  alt={"Recon"}
                  w={[56, 60, 60, 72]}
                  className="logo-wrap"
                />
              </Box>
            </HStack>
            {/* {!isListLoading && (
              <Grid
                gap="4"
                templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)"]}
                flex="1"
                verticalAlign={"middle"}
              >
                <GridItem className="org-option-wrap">
                  <Select
                    placeholder="Select an Organization"
                    rounded={"md"}
                    backgroundColor={"#fff"}
                    color={"#23282C"}
                    className="select-org-wrap"
                  >
                    {organizationList?.map((item: any) => (
                      <option value={item.id} key={item.id}>
                        {item.org_name}
                      </option>
                    ))}
                  </Select>
                </GridItem>
              </Grid>
            )} */}
          </Flex>
          <Flex className="right-content-wrapper" alignItems={"center"}>
            <Menu>
              <HStack spacing={8} alignItems={"center"}>
                <Text fontWeight={600}>Howdy, {user.first_name}.</Text>

                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    size={"sm"}
                    src={""}
                    name={`${user.first_name} ${user.last_name}`}
                    border={"1px solid white"}
                    background={"transparent"}
                  ></Avatar>
                </MenuButton>
              </HStack>
              <MenuList color={"#000"}>
                <MenuItem
                  onClick={() => {
                    navigate(routes.settings.index);
                  }}
                  className="setting-dropdown-menu"
                >
                  <Center px={"4"}>
                    <Text aria-label={"Settings"} color="blue.300">
                      <AiOutlineSetting />
                    </Text>
                    Settings
                  </Center>
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  onClick={onLogoutClick}
                  className="logout-dropdown-menu"
                >
                  <Text aria-label={"Settings"} color="blue.300">
                    <BiLogOut />
                  </Text>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default withAuthState(Nav);
