import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
} from "@chakra-ui/react";
import { UserListItemType } from "interface/user/userListItem";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const EditUserForm: React.FC<UserListItemType> = (props) => {
  const { listData, showRole, roleList } = props;
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<any>();

  useEffect(() => {
    if (!listData?.role && roleList.length === 0) return;
    setValue("role", listData.role);
  }, [setValue, listData, roleList]);

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
        flex="1"
      >
        <GridItem>
          <FormControl isInvalid={!!errors?.first_name} isRequired>
            <FormLabel>First Name</FormLabel>
            <Input
              {...register(`first_name`, {
                required: "First Name",
                maxLength: {
                  value: 255,
                  message: "Too many characters.",
                },
              })}
              type="text"
              defaultValue={listData?.first_name}
              placeholder={"First Name"}
            />
            <FormErrorMessage>
              {!!errors?.first_name && !!errors?.first_name?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.last_name} isRequired>
            <FormLabel>Last Name</FormLabel>
            <Input
              {...register(`last_name`, {
                required: "Last Name",
                maxLength: {
                  value: 255,
                  message: "Too many characters.",
                },
              })}
              type="text"
              defaultValue={listData?.last_name}
              placeholder={"Last Name"}
            />
            <FormErrorMessage>
              {!!errors?.last_name && !!errors?.last_name?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isInvalid={!!errors?.email} isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              {...register(`email`, {
                required: "email",
              })}
              type="email"
              defaultValue={listData?.email}
              placeholder={"Email"}
            />
            <FormErrorMessage>
              {!!errors?.email && !!errors?.email?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        {!!showRole && (
          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors?.email} isRequired>
              <FormLabel>Permission</FormLabel>
              <Select
                placeholder="Select Permission"
                {...register(`role`, {
                  required: "Permission",
                })}
                defaultValue={listData?.role}
              >
                {roleList?.map((item: any) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>
                {!!errors?.role && !!errors?.role?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        )}
      </Grid>
    </Stack>
  );
};

export default EditUserForm;
