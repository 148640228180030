import axios from "axios";
import http from "utils/http";

export function login(data: any) {
  return http({
    url: "/token/",
    method: "post",
    data: {...data, request_from: "admin" },
  });
}

export function logout(refreshToken: string) {
  return http({
    url: "/logout",
    method: "post",
    data: { refresh_token: refreshToken },
  });
}

export function forgotPassword(data: any) {
  return http({
    url: "/forgot-password/",
    method: "post",
    data: data,
  });
}

export function changePassword(data: any, headers?: any) {
  return http({
    url: "/update-password/",
    method: "post",
    data: data,
    headers,
  });
}

export function resetPassword(query: any, data: any) {
  return http({
    url: `/update-password/`,
    method: "post",
    data: data,
  });
}

export function setPassword(data: any) {
  return http({
    url: `/set-password/`,
    method: "post",
    data: data,
  });
}

export function verifyEmail(data: any) {
  return http({
    url: "/verify-email",
    method: "post",
    data: data,
  });
}

export function verifyUserPasswordRefreshToken(uid: any, $token: any) {
  return http({
    url: `/ui-token-verify/${uid}/${$token}`,
    method: "get",
  });
}

const CancelToken = axios.CancelToken;
let cancel: any;

export function refreshUser(refreshToken: string) {
  if (cancel) {
    cancel(); // cancel request
  }

  return http({
    url: "/token/refresh/",
    method: "post",
    data: { refresh: refreshToken },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
}
