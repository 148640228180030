// Auth
export const ACCESS_TOKEN = "token";
export const REFRESH_TOKEN = "refreshToken";
export const EXPIRES_IN = "expires";

// Pagination

export const DEFAULT_PAGE_SIZE = 15;

export const INITIAL_CURRENT_PAGE = 1;
export const PAGE_LIMITS = [5, 15, 30, 50, 100];

export const ZIP_EXTENSION =
  "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed";

export const ASSESSMENT_FREQUENCY = [
  { id: "6M", name: "6 months" },
  { id: "1Y", name: "1 Year" },
  { id: "2Y", name: "2 Years" },
  { id: "3Y", name: "3 Years" },
  { id: "4Y", name: "4 Years" },
  { id: "5Y", name: "5 Years" },
];

export const publicAPI = ["/token/", "/reset-password/", "/set-password/"];
