import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import OrganizationResource from "api/organization";
import UserResource from "api/user";
import OrganizationForm from "components/organization/OrganizationForm";
import UserForm from "components/user/UserCreateForm";
import { OrganizationDataSchema } from "interface/organization/organizationListItem";
import { UserSchema } from "interface/user/userListItem";
import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import "./organization.css";

const CreateOrganization: React.FC<OrganizationDataSchema> = (props) => {
  const { setDeleteModalOpen } = props;

  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  useNavigate();
  const methods = useForm<UserSchema>();
  const userAPI = new UserResource();
  const api = new OrganizationResource();

  const [uploadDetail, setUploadDetail] = useState({ id: "", file_doc: "" });
  const [errorMessage, setErrorMessage] = useState<any>();
  const [showInfrastructureError, setShowInfrastructureError] = useState<boolean>(false)
  const [userData, setUserData] = useState<any>();
  // add more just a array to loop through to get desired number of rows to add new users
  const [addMore, setAddMore] = useState([{}]);

  const addUser = useMutation(
    (orgId: any) => {
      const userArray = userData.map((user: any) => {
        return {
          ...user,
          organization: orgId,
        };
      });
      return userAPI.storeUser(userArray);
    },
    {
      onSuccess: (res: any) => {
        toast({
          position: "top",
          title: "Organization created with users.",
          status: "success",
          isClosable: true,
        });
        queryClient.invalidateQueries("organizationList");
        setDeleteModalOpen(false);
      },
      onError: (error: any) => {
        setErrorMessage("User Creation Failed.");
      },
    }
  );

  const addOrganization = useMutation((data: any) => api.store(data), {
    onSuccess: (res: any) => {
      const { id: orgId } = res.data;
      addUser.mutate(orgId);
    },
    onError: (error: any) => {
      setErrorMessage("Organization Creation Failed.");
    },
  });

  const addRow = () => {
    // add new {} to addMore in order to add new row in the add list
    setAddMore([...new Set(addMore), {}]);
  };

  const onSubmit = (data: any) => {
    const orgData = {
      org_name: data.org_name,
      infrastructure_assessment_frequency:
        data.infrastructure_assessment_frequency,
      file_url: "",
      file_name: data?.file_doc?.name,
    };

    if (uploadDetail?.file_doc) {
      orgData.file_url = uploadDetail.file_doc;
    }
    setShowInfrastructureError(false);

    if (orgData.file_url === "") {
      toast({
        position: "top",
        title: "Infrastructure file must not be empty.",
        status: "error",
        isClosable: true,
      });
      setShowInfrastructureError(true);
      return false;
    }

    setUserData(data.users);
    addOrganization.mutate(orgData);
  };

  return (
    <Box bg="white" p={["3", "6"]} shadow="box" rounded="sm">
      <Flex justify="space-between" py={4}>
        <Heading size="md" color={"#23282C"} className="header-text">
          Add Organization
        </Heading>
      </Flex>
      <Divider mb={4} />
      <FormProvider {...methods}>
        <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack direction="column" spacing="4">
            {addOrganization.isError && (
              <Alert status="error">
                <AlertIcon />
                {errorMessage}
              </Alert>
            )}
            <OrganizationForm 
            setUploadDetail={setUploadDetail}
             showInfrastructureError={showInfrastructureError} setShowInfrastructureError={setShowInfrastructureError} />
            <Flex justify="space-between" mt={4}>
              <Heading size="md" color={"#23282C"} className="header-text">
                Add Users
              </Heading>
            </Flex>
            <Divider mb={4} />

            <UserForm addMore={addMore} showRole={true} isOrgAddPage={true} />

            <Button
              className="link-btn add-user-btn"
              maxW={"80px"}
              onClick={addRow}
            >
              + Add User
            </Button>
          </Stack>

          <ButtonGroup>
            <Button
              colorScheme="primary"
              type="submit"
              isLoading={addOrganization.isLoading}
              backgroundColor={"#000000"}
              _hover={{ backgroundColor: "#000000" }}
              className="send-invite-btn"
            >
              Send Invite
            </Button>
          </ButtonGroup>
        </form>
      </FormProvider>
    </Box>
  );
};

export default CreateOrganization;
