import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { setLoggedInUser } from "actions/data/auth";
import { changePassword } from "api/auth";
import UserResource from "api/user";
import { nameDetails } from "constants/data";
import routes from "constants/routes";
import {
  ChangePasswordInput,
  ProfileInformationForm,
} from "interface/user/userInformation";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { BiHide, BiShow } from "react-icons/bi";
import { useMutation } from "react-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as authService from "services/auth";
import { validatePassword } from "utils/validate";
import "./settings.css";

const Setting: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(
    (state: any) => ({
      user: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const toast = useToast();
  const userApi = new UserResource();

  const mutateUserData = useMutation(
    (data: any) => userApi.update(user.id, data),
    {
      onSuccess: (res: any) => {
        toast({
          position: "top",
          title: "User detail has been updated",
          status: "success",
          isClosable: true,
        });

        dispatch(setLoggedInUser(res.data));
      },
      onError: (res: any) => {
        toast({
          position: "top",
          title: "Unable update user detail!",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const mutatePassword = useMutation((data: any) => changePassword(data), {
    onSuccess: (res: any) => {
      toast({
        position: "top",
        title: "Please login with new password.",
        status: "success",
        isClosable: true,
      });

      authService.logout();
      navigate(routes.auth.login);
    },
    onError: (res: any) => {
      toast({
        position: "top",
        title: "Unable update user detail!",
        status: "error",
        isClosable: true,
      });
    },
  });

  const [isChangePasswordShow, setIsChangePasswordShow] =
    useState<boolean>(false);
  const [viewCurrentPassword, setViewCurrentPassword] =
    useState<boolean>(false);
  const [viewNewPassword, setViewNewPassword] = useState<boolean>(false);
  const [viewConfirmPassword, setViewConfirmPassword] =
    useState<boolean>(false);
  const [showChangeName, setShowChangeName] = useState<boolean>(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
    getValues,
    reset,
  } = useForm<ChangePasswordInput>();
  const nameMethods = useForm<ProfileInformationForm>();

  const openChangePassword = () => {
    reset();
    setIsChangePasswordShow(true);
  };

  const cancelChangeName = () => {
    nameMethods.reset();
    setShowChangeName(false);
  };

  const cancelChangeLogin = () => {
    nameMethods.reset();
    setIsChangePasswordShow(false);
  };

  const onPasswordChange = async (data: ChangePasswordInput) => {
    reset();
    mutatePassword.mutate(data);
    setIsChangePasswordShow(false);
  };

  const onSubmitName = async (data: ProfileInformationForm) => {
    nameDetails.full_name = data?.name
      ? data?.name
      : data.first_name + " " + data.last_name;
    nameMethods.setValue("full_name", data.full_name);
    nameMethods.setValue("first_name", data.first_name);
    nameMethods.setValue("last_name", data.last_name);
    const nameData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: user.email,
    };
    mutateUserData.mutate(nameData);

    setShowChangeName(false);
  };

  return (
    <Stack direction="column" spacing="6" className="settings--wrapper">
      <Flex justify="space-between" className="settings-content-wrapper">
        <Heading size="md" color={"#1A1B1F"} className="header-text ">
          Settings
        </Heading>
      </Flex>
      <Box
        bg="white"
        p={["3", "6"]}
        shadow="box"
        rounded="sm"
        className="settings-content-wrapper"
      >
        <Heading size="md" ml={4} color={"#1A1B1F"} className="subheading-text">
          Profile
        </Heading>
        <Flex
          w={"100%"}
          justifyContent={"space-between"}
          className="box-content--wrap"
        >
          {!showChangeName && (
            <Flex w={"80%"}>
              <Text w={"20%"} p={"4"} color={"#3A3633"} fontWeight={"600"}>
                Name
              </Text>
              <Text w={"70%"} p={"4"} color={"#3A3633"}>
                <p>{`${user.first_name} ${user.last_name}`}</p>
              </Text>
              <Button
                backgroundColor={"#rgba(86, 170, 195, 0.2)"}
                color={"#56AAC3"}
                rounded={"md"}
                onClick={() => setShowChangeName(!showChangeName)}
                className="edit-profile"
              >
                Edit
              </Button>
            </Flex>
          )}
          {showChangeName && (
            <form
              onSubmit={nameMethods.handleSubmit(onSubmitName)}
              className="profile-edit-form"
            >
              <FormControl
                colorScheme="primary"
                isInvalid={!!nameMethods.formState.errors.first_name}
                ms={24}
              >
                <FormLabel mt={"4"}>First Name</FormLabel>
                <InputGroup display={"block"}>
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <Input
                      width={"100%"}
                      placeholder="First Name"
                      type={"text"}
                      {...nameMethods.register("first_name", {
                        required: "First Name",
                      })}
                      defaultValue={user.first_name}
                    />
                  </div>
                  <FormErrorMessage>
                    {nameMethods.formState.errors.first_name &&
                      nameMethods.formState.errors.first_name?.message}
                  </FormErrorMessage>
                </InputGroup>
              </FormControl>
              <FormControl
                colorScheme="primary"
                isInvalid={!!nameMethods.formState.errors.first_name}
                ms={24}
              >
                <FormLabel mt={"4"}>Last Name</FormLabel>
                <InputGroup display={"block"}>
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <Input
                      width={"100%"}
                      placeholder="Last Name"
                      type={"text"}
                      {...nameMethods.register("last_name", {
                        required: "Last Name",
                      })}
                      defaultValue={user.last_name}
                    />
                  </div>
                  <FormErrorMessage>
                    {nameMethods.formState.errors.last_name &&
                      nameMethods.formState.errors.last_name?.message}
                  </FormErrorMessage>
                </InputGroup>
              </FormControl>
              <ButtonGroup ms={"20"}>
                <Button
                  type="submit"
                  margin={"1rem"}
                  isLoading={nameMethods.formState.isSubmitting}
                  backgroundColor={"#000000"}
                  color={"#FFFFFF"}
                  rounded="md"
                >
                  Save
                </Button>
                <Button
                  margin={"1rem"}
                  backgroundColor={"#FFFFFF"}
                  color={"#000000"}
                  rounded="md"
                  border={"1px"}
                  onClick={() => {
                    cancelChangeName();
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </form>
          )}
        </Flex>
        <Flex
          w={"100%"}
          justifyContent={"space-between"}
          className="box-content--wrap"
        >
          <Text w={"20%"} p={"4"} color={"#3A3633"} fontWeight={"600"}>
            Email
          </Text>
          <Text w={"80%"} p={"4"} color={"#3A3633"} className="setting-text">
            <p>{user.email}</p>
          </Text>
        </Flex>
      </Box>
      <Box
        bg="white"
        p={["3", "6"]}
        shadow="box"
        rounded="sm"
        className="settings-content-wrapper"
      >
        <Heading size="md" ml={4} color={"#1A1B1F"}>
          Password
        </Heading>
        <Flex w={"100%"}>
          {!isChangePasswordShow && (
            <>
              <Text w={"50%"} p={"4"} color={"#3A3633"} fontWeight={"600"}>
                Change Password
              </Text>
              <Text w={"70%"} p={"4"} color={"#3A3633"}>
                <p></p>
              </Text>
              <Button
                backgroundColor={"#rgba(86, 170, 195, 0.2)"}
                color={"#56AAC3"}
                rounded={"md"}
                onClick={() => openChangePassword()}
                className="edit-profile"
              >
                Edit
              </Button>
            </>
          )}
          {isChangePasswordShow && (
            <Flex w={"60%"}>
              <form
                onSubmit={handleSubmit(onPasswordChange)}
                className="change-password-form"
              >
                <Text color={"#737373"} p={"4"}>
                  Use 4 or more characters with a mix of letters, numbers and
                  characters
                </Text>
                <FormControl
                  colorScheme="primary"
                  isInvalid={!!errors.current_password}
                  ps={"4"}
                >
                  <FormLabel>Current Password</FormLabel>
                  <InputGroup display={"block"}>
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <Input
                        className={"settings-input"}
                        width={"100%"}
                        placeholder="********"
                        type={viewCurrentPassword ? "text" : "password"}
                        {...register("current_password", {
                          required: "Current Password",
                        })}
                      />
                      <InputRightElement>
                        <IconButton
                          className="eye-button"
                          bg={"transparent"}
                          size="sm"
                          icon={viewCurrentPassword ? <BiHide /> : <BiShow />}
                          title={
                            viewCurrentPassword
                              ? "Hide Password"
                              : "Show Password"
                          }
                          onClick={() => {
                            setViewCurrentPassword(!viewCurrentPassword);
                          }}
                          aria-label={viewCurrentPassword ? "Hide" : "Show"}
                        />
                      </InputRightElement>
                    </div>
                    <FormErrorMessage>
                      {errors.current_password &&
                        errors.current_password?.message}
                    </FormErrorMessage>
                  </InputGroup>
                </FormControl>
                <FormControl
                  colorScheme="primary"
                  ps={"4"}
                  isInvalid={!!errors.password}
                >
                  <FormLabel>Password</FormLabel>
                  <InputGroup display={"block"}>
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <Input
                        className={"settings-input"}
                        width={"100%"}
                        placeholder="********"
                        type={viewNewPassword ? "text" : "password"}
                        {...register("password", {
                          required: "Password",
                          validate: (value) =>
                            validatePassword(value) ||
                            "Please provide valid password.",
                        })}
                      />
                      <InputRightElement>
                        <IconButton
                          className="eye-button"
                          bg={"transparent"}
                          size="sm"
                          icon={viewNewPassword ? <BiHide /> : <BiShow />}
                          title={
                            viewNewPassword ? "Hide Password" : "Show Password"
                          }
                          onClick={() => {
                            setViewNewPassword(!viewNewPassword);
                          }}
                          aria-label={viewNewPassword ? "Hide" : "Show"}
                        />
                      </InputRightElement>
                    </div>
                    <FormErrorMessage>
                      {errors.password && errors.password.message}
                    </FormErrorMessage>
                  </InputGroup>
                </FormControl>

                <FormControl
                  ps={"4"}
                  colorScheme="primary"
                  isInvalid={!!errors.confirm_password}
                >
                  <FormLabel>Confirm Password</FormLabel>
                  <InputGroup display={"block"}>
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <Input
                        className={"settings-input"}
                        width={"100%"}
                        placeholder="********"
                        type={viewConfirmPassword ? "text" : "password"}
                        {...register("confirm_password", {
                          required: "Confirm Password",
                          validate: (value) =>
                            value === getValues("password") ||
                            "The passwords do not match.",
                        })}
                      />
                      <InputRightElement>
                        <IconButton
                          className="eye-button"
                          bg={"transparent"}
                          size="sm"
                          icon={viewConfirmPassword ? <BiHide /> : <BiShow />}
                          title={
                            viewConfirmPassword
                              ? "Hide Password"
                              : "Show Password"
                          }
                          onClick={() => {
                            setViewConfirmPassword(!viewConfirmPassword);
                          }}
                          aria-label={viewConfirmPassword ? "Hide" : "Show"}
                        />
                      </InputRightElement>
                    </div>
                    <FormErrorMessage>
                      {errors.confirm_password &&
                        errors.confirm_password.message}
                    </FormErrorMessage>
                  </InputGroup>
                </FormControl>
                <ButtonGroup>
                  <Button
                    type="submit"
                    margin={"1rem"}
                    isLoading={formState.isSubmitting}
                    backgroundColor={"#000000"}
                    color={"#FFFFFF"}
                    rounded="md"
                  >
                    Save
                  </Button>
                  <Button
                    margin={"1rem"}
                    backgroundColor={"#FFFFFF"}
                    color={"#000000"}
                    rounded="md"
                    border={"1px"}
                    onClick={() => {
                      cancelChangeLogin();
                    }}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </form>
            </Flex>
          )}
        </Flex>
      </Box>
    </Stack>
  );
};

export default Setting;
