export const nameDetails = {
  first_name: "Sushan",
  last_name: "Shrestha",
  full_name: "Sushan Shrestha",
  email: "sushan.shr100@gmail.com",
};

export const columns = [
  {
    label: "Name",
    accessor: "first_name",
    sortByOrder: "asc",
    sortable: false,
  },
  { label: "Email", accessor: "email", sortByOrder: "asc", sortable: false },
  {
    label: "Status",
    accessor: "is_active",
    sortByOrder: "asc",
    sortable: false,
  },
  {
    label: "Date Added",
    accessor: "date_joined",
    sortByOrder: "asc",
    sortable: false,
  },
];

export const dataList = {
  meta: {
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 15,
    to: 6,
    total: 6,
  },
  data: [
    {
      id: 1,
      first_name: "sushan",
      last_name: "shrestha",
      email: "sushan.shr100@gmail.com",
      status: "active",
      createdAt: new Date("2022-10-11"),
    },
    {
      id: 2,
      first_name: "sushan1",
      last_name: "shrestha1",
      email: "sushan.shr1000@gmail.com",
      status: "active",
      createdAt: new Date("2022-10-13"),
    },
    {
      id: 3,
      first_name: "sushan2",
      last_name: "shrestha2",
      email: "sushan.shr10000@gmail.com",
      status: "invited",
      createdAt: new Date("2022-10-15"),
    },
    {
      id: 4,
      first_name: "abc",
      last_name: "xyz",
      email: "abc@gmail.com",
      status: "active",
      createdAt: new Date("2022-10-20"),
    },
    {
      id: 5,
      first_name: "cde",
      last_name: "edc",
      email: "cde@gmail.com",
      status: "active",
      createdAt: new Date("2022-12-13"),
    },
    {
      id: 6,
      first_name: "efg",
      last_name: "gfe",
      email: "efg@gmail.com",
      status: "active",
      createdAt: new Date("2023-01-13"),
    },
  ],
};

export const organizationListColumns = [
  {
    label: "Organization Name",
    accessor: "org_name",
    sortByOrder: "asc",
    sortable: false,
  },
  { label: "Users", accessor: "users", sortByOrder: "asc", sortable: false },
  {
    label: "Infrastructures",
    accessor: "infrastructure",
    sortByOrder: "asc",
    sortable: false,
  },
];

export const organizationDataList = {
  meta: {
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 15,
    to: 6,
    total: 6,
  },
  data: [
    {
      id: 1,
      org_name: "ABC company",
      users: 10,
      upload: "abc.zip",
      infrastructures: 10,
    },
    {
      id: 2,
      org_name: "ABC2 company",
      users: 100,
      infrastructures: 300,
    },
    {
      id: 3,
      org_name: "ABC3 company",
      users: 110,
      upload: "acc.zip",
      infrastructures: 350,
    },
    {
      id: 4,
      org_name: "ABC4 company",
      users: 99,
      infrastructures: 500,
    },
    {
      id: 5,
      org_name: "XYZ company",
      upload: "acrc.zip",
      users: 510,
      infrastructures: 1250,
    },
    {
      id: 6,
      org_name: "DAC company",
      users: 90,
      infrastructures: 650,
    },
  ],
};
