import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import OrganizationResource from "api/organization";
import OrganizationForm from "components/organization/OrganizationForm";
import { UserSchema } from "interface/user/userListItem";
import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import UserList from "../users/UserList";
import "./organization.css";

const EditOrganization: React.FC = () => {
  const { id }: any = useParams();

  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [uploadDetail, setUploadDetail] = useState({ id: "", file_doc: "" });
  const [uploadHexDetail, setUploadHexDetail] = useState({ id: "", file_doc: "" });
  const [isHexFileRemoved, setIsHexFileRemoved] = useState(false);

  const toast = useToast();
  // const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  // const navigate = useNavigate();
  const methods = useForm<UserSchema>();
  const organizationAPI = new OrganizationResource();

  const [errorMessage, setErrorMessage] = useState<any>();
  const [listData, setListData] = useState<any>();
  const [showInfrastructureError,setShowInfrastructureError] = useState<boolean>(false)

  useQuery(
    ["organizationDetail"],
    async () => {
      const response = await organizationAPI.get(id);
      setListData(response?.data);
      return response?.data?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
console.log(listData,'list data in edit organisation')
  const updateOrganization = useMutation(
    (data: any) => organizationAPI.update(id, data),
    {
      onSuccess: (res: any) => {
        toast({
          position: "top",
          title: "Organization detail has been updated.",
          status: "success",
          isClosable: true,
        });
      },
      onError: (error: any) => {
        setErrorMessage("Updating organization detail failed.");
      },
    }
  );

  const onSubmit = (data: any) => {
    delete data.file_name;
    delete data.file_url;

    if (isFileRemoved) {
      data.file_url = "";
      data.file_name = "";
    }
    if(isHexFileRemoved){
      data.hex_file = "";
    }
        
    if (uploadHexDetail) {
      data.hex_file =
        uploadHexDetail?.file_doc !== "" ? uploadHexDetail?.file_doc : undefined;
      data.hex_file = data?.hex_file?.name;
    }
    if (uploadDetail) {
      data.file_url =
        uploadDetail?.file_doc !== "" ? uploadDetail?.file_doc : undefined;
      data.file_name = data?.file_doc?.name;
    }

    updateOrganization.mutate(data);
  };

  
  return (
    <Stack direction="column" spacing="4" className="organization--wrapper">
      <Flex justify="space-between">
        <Heading size="md" color={"#23282C"} className="header-text">
          Edit Organization
        </Heading>
      </Flex>

      <Box bg="white" p={["3", "6"]} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateOrganization.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
         {listData&&     <OrganizationForm
                listData={listData}
                setIsFileRemoved={setIsFileRemoved}
                setUploadDetail={setUploadDetail}
                setUploadHexDetail={setUploadHexDetail}
                setIsHexFileRemoved={setIsHexFileRemoved}
                showInfrastructureError={showInfrastructureError}
                setShowInfrastructureError={setShowInfrastructureError}
              />}
              <Button
                mt={"8 !important"}
                colorScheme="primary"
                type="submit"
                isLoading={updateOrganization.isLoading}
                backgroundColor={"#000000"}
                w={"15%"}
                rounded={"md"}
                _hover={{ backgroundColor: "#000000" }}
              >
                Save Changes
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Box>
      <UserList listData={listData} showRole={true} />
    </Stack>
  );
};

export default EditOrganization;
