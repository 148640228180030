import Resource from "api/resource";
import http from "utils/http";

class UserResource extends Resource {
  constructor() {
    super("users");
  }

  resendInvite(resource: any, headers?: any) {
    return http({
      url: "/resend-invitation/",
      method: "post",
      data: resource,
      headers: headers,
    });
  }

  resetPassword(resource: any, headers?: any) {
    return http({
      url: "/reset-password/",
      method: "post",
      data: resource,
      headers: headers,
    });
  }

  userList(query?: any) {
    return http({
      url: "/user-list/",
      method: "get",
      params: query,
    });
  }

  storeUser(resource: any, headers?: any) {
    return http({
      url: "/invitation/",
      method: "post",
      data: resource,
      headers: headers,
    });
  }
}

export { UserResource as default };
