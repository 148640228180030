import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import OrganizationResource from "api/organization";
import routes from "constants/routes";
import {
  OrganizationListItemType,
  OrganizationListSchema,
} from "interface/organization/organizationListItem";
import React, { useState } from "react";
import { CgTrashEmpty } from "react-icons/cg";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

const OrganizationListItem: React.FC<OrganizationListItemType> = (props) => {
  const { listData } = props;
  const organizationApi = new OrganizationResource();

  const navigate = useNavigate();
  const [deleteMsg, setDeleteMsg] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();

  const handleShowDialog = (data: OrganizationListSchema) => {
    navigate(
      routes.organization.edit.replace(
        ":id",
        data?.id ? data.id.toString() : ""
      )
    );
  };

  const deleteOrganizationMutation = useMutation(
    () => organizationApi.destroy(listData?.id),
    {
      onSuccess: (res: any) => {
        toast({
          position: "top",
          title: "Organization has been deleted!",
          status: "success",
          isClosable: true,
        });
        queryClient.invalidateQueries("organizationList");

        setDeleteModalOpen(false);
      },
      onError: (res: any) => {
        toast({
          position: "top",
          title: "Unable to delete organization at this moment!",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const deleteOrganization = () => {
    setDeleteMsg("");
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg("");
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteOrganizationMutation.mutate();
  };

  return (
    <>
      <Tr key={listData?.id}>
        <Td>
          <Text
            color={"#3A3633"}
            fontWeight={"bold"}
            onClick={() => handleShowDialog(listData)}
            className={"name-pointer"}
          >
            {listData?.org_name}
          </Text>
        </Td>
        <Td>{listData?.users}</Td>
        <Td>{listData?.infrastructures}</Td>
        <Td>
          <CgTrashEmpty onClick={() => deleteOrganization()} />
        </Td>
      </Tr>
      <Modal
        isOpen={isDeleteModalOpen}
        id={"delete_modal"}
        isCentered
        onClose={onDeleteModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{"Delete Organization"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody boxShadow={"none !important"}>
            {"Are you sure you want to delete this ?"}
            {deleteMsg && (
              <Alert status="error" mb={2}>
                <AlertIcon />
                {deleteMsg}
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="red"
                onClick={onDeleteConfirm}
                isLoading={deleteOrganizationMutation.isLoading}
              >
                {"Delete"}
              </Button>

              <Button variant="outline" onClick={onDeleteModalClose}>
                {"Cancel"}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrganizationListItem;
