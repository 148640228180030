import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
} from "@chakra-ui/react";
import RoleResource from "api/role";
import { UserListItemType } from "interface/user/userListItem";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useQuery } from "react-query";

const UserForm: React.FC<UserListItemType> = (props) => {
  const api = new RoleResource();
  const { listData, addMore, organizationId, showRole, isOrgAddPage } = props;
  const [roleList, setroleList] = useState([]);

  const {
    register,
    formState: { errors },
  } = useFormContext<any>();

  const viewRole = () => {
    return (!!organizationId && !!showRole) || !!isOrgAddPage;
  };

  useQuery(["roleList"], async () => {
    const response = await api.list();
    response?.data?.roles.shift();
    setroleList(response?.data?.roles);
  });

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)"]}
        flex="1"
      >
        {addMore.map((item: any, index: number) => (
          <Grid key={index}
          gap="4"
          templateColumns={
            !!organizationId || !!showRole
              ? ["repeat(1, 1fr)", "repeat(4, 1fr)"]
              : ["repeat(1, 1fr)", "repeat(3, 1fr)"]
          }
          flex="1">
            <GridItem>
              <FormControl isInvalid={!!errors?.first_name} isRequired>
                {!(index > 0) && <FormLabel>First Name</FormLabel>}
                <Input
                  {...register(`users[${index}][first_name]`, {
                    required: "First Name",
                    maxLength: {
                      value: 255,
                      message: "Too many characters.",
                    },
                  })}
                  type="text"
                  defaultValue={listData?.first_name}
                  placeholder={"First Name"}
                />
                <FormErrorMessage>
                  {!!errors?.first_name && !!errors?.first_name?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={!!errors?.last_name} isRequired>
                {!(index > 0) && <FormLabel>Last Name</FormLabel>}
                <Input
                  {...register(`users[${index}][last_name]`, {
                    required: "Last Name",
                    maxLength: {
                      value: 255,
                      message: "Too many characters.",
                    },
                  })}
                  type="text"
                  defaultValue={listData?.last_name}
                  placeholder={"Last Name"}
                />
                <FormErrorMessage>
                  {!!errors?.last_name && !!errors?.last_name?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={!!errors?.email} isRequired>
                {!(index > 0) && <FormLabel>Email</FormLabel>}
                <Input
                  {...register(`users[${index}][email]`, {
                    required: "email",
                  })}
                  type="email"
                  defaultValue={listData?.email}
                  placeholder={"Email"}
                />
                <FormErrorMessage>
                  {!!errors?.email && !!errors?.email?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            {viewRole() && (
              <>
                <GridItem>
                  <FormControl isInvalid={!!errors?.role} isRequired>
                    {!(index > 0) && <FormLabel>Permission</FormLabel>}
                    <Select
                      placeholder="Select Permission"
                      {...register(`users[${index}][role]`, {
                        required: "Permission",
                      })}
                    >
                      {roleList?.map((item: any) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>

                    <FormErrorMessage>
                      {!!errors?.role && !!errors?.role?.message}
                    </FormErrorMessage>
                    <Input
                      {...register(`users[${index}][organization_id]`)}
                      type="hidden"
                      defaultValue={!!organizationId ? organizationId : ""}
                    />
                  </FormControl>
                </GridItem>
              </>
            )}
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default UserForm;
