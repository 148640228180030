import Resource from "api/resource";
import http from "utils/http";

class OrganizationResource extends Resource {
  constructor() {
    super("organizations");
  }

  organizationUsersList(organiztionId?: Number, query?: any) {
    return http({
      url: `/organizations/${organiztionId}/users/`,
      method: "get",
      params: query,
    });
  }
}

export { OrganizationResource as default };
