import { Box, Image } from "@chakra-ui/react";
import { Logo } from "assets/images";

export default function TopBar() {
  return (
    <>
      <Box bg={"#393A3B"} px={4} h={20}>
        <Image
          className="logo-wrap"
          src={Logo}
          alt={"Recon"}
          color={"white"}
          w={[56, 60, 64, 60]}
        />
      </Box>
    </>
  );
}
