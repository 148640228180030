import {
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Image,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Logo } from "assets/images";
import routes from "constants/routes";
import React, { ReactNode, ReactText } from "react";
import { IconType } from "react-icons";
import { FiMenu, FiUser } from "react-icons/fi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

interface LinkItemProps {
  name: string;
  icon: IconType;
  url: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Admin Users", icon: FiUser, url: routes.users.list },
  // { name: "Reports", icon: BsPencil, url: routes.reports.index },
  // { name: "Chat", icon: GrMail, url: routes.chats.list },
  {
    name: "Organization",
    icon: HiOutlineUserGroup,
    url: routes.organization.list,
  },
  // { name: "Assessments", icon: BsPencil, url: routes.reports.index },
];

const Sidebar: React.FC<any> = ({ children }: { children: ReactNode }) => {
  useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <SidebarContent onClose={() => onClose} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="xs"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </>
  );
};

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const current = window.location.pathname;
  return (
    <Box
      bg={"#FFFFFF"}
      borderRight="2px"
      borderColor={"#E8E8E8"}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
      className="sidebar---wrapper"
    >
      <Flex alignItems="center" mx="8" justifyContent="end">
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          url={link.url}
          active={
            current.split("/")[1] === link.url.split("/")[1] ? true : false
          }
          // backgroundColor={}
          // _active={{ backgroundColor: "red" }}
          className={"sidebar_icons-wrapper"}
          _activeLink={{ color: "red" }}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  url: string;
  active: boolean;
}
const NavItem = ({ icon, children, url, active, ...rest }: NavItemProps) => {
  return (
    <Link to={url} style={{ textDecoration: "none" }}>
      <Stack flexDirection={"row"} align="start" justify={"space-between"}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          fontWeight={active ? "bold" : "normal"}
          _hover={{
            fontWeight: "700",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "white",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
        <Box
          style={{
            borderColor: active ? "#23282C" : "white",
            borderRadius: 3,
            backgroundColor: active ? "#23282C" : "transparent",
            minWidth: 4,
            height: 40,
            fontWeight: "bold",
          }}
        />
      </Stack>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
      className="hambruger-menu-wrap"
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Box>
        <Image src={Logo} alt={"Recon"} className="sidebar-logo-wrap" />
      </Box>
    </Flex>
  );
};

export default Sidebar;
