import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import UserResource from "api/user";
import dayjs from "dayjs";
import { UserListItemType, UserSchema } from "interface/user/userListItem";
import EditUser from "pages/dashboard/users/EditUser";
import React, { useState } from "react";
import { CgTrashEmpty } from "react-icons/cg";
import { useMutation, useQueryClient } from "react-query";

const UserListItem: React.FC<UserListItemType> = (props) => {
  const { listData, showRole } = props;
  const [isEditUserModalOpen, setEditUserModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const userApi = new UserResource();
  const toast = useToast();
  const queryClient = useQueryClient();

  const reInviteMutation = useMutation(
    (email: string) => userApi.resendInvite([{ email }]),
    {
      onSuccess: (res: any) => {
        toast({
          position: "top",
          title: "Invitation has been re-sent!",
          status: "success",
          isClosable: true,
        });
      },
      onError: (res: any) => {
        toast({
          position: "top",
          title: "Unable to send invitation at this moment!",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const resetPasswordMutation = useMutation(
    (email: string) => userApi.resetPassword({ email }),
    {
      onSuccess: (res: any) => {
        toast({
          position: "top",
          title: "Reset password link has been sent!",
          status: "success",
          isClosable: true,
        });
      },
      onError: (res: any) => {
        toast({
          position: "top",
          title: "Unable to send reset password link at this moment!",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const deleteUserMutation = useMutation(() => userApi.destroy(listData?.id), {
    onSuccess: (res: any) => {
      toast({
        position: "top",
        title: "User has been deleted!",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries("organizationUserList");

      setDeleteModalOpen(false);
    },
    onError: (res: any) => {
      toast({
        position: "top",
        title: "Unable to delete user at this moment!",
        status: "error",
        isClosable: true,
      });
    },
  });

  const resendInvite = (email: string) => {
    reInviteMutation.mutate(email);
  };

  const resetPassword = (email: string) => {
    resetPasswordMutation.mutate(email);
  };

  const deleteUser = () => {
    setDeleteMsg("");
    setDeleteModalOpen(true);
  };

  const handleShowDialog = (data: UserSchema) => {
    setEditUserModalOpen(true);
  };

  const onEditUserModalClose = () => {
    setEditUserModalOpen(false);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg("");
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteUserMutation.mutate();
  };

  return (
    <>
      <Tr key={listData?.id}>
        <Td>
          <Text
            color={"#3A3633"}
            fontWeight={"bold"}
            onClick={() => handleShowDialog(listData)}
            className={"name-pointer"}
          >
            {`${listData?.first_name} ${listData?.last_name}`}
          </Text>
        </Td>
        <Td>{listData?.email}</Td>
        <Td>
          <Text color={listData?.is_active === false ? "#737373" : "#56AAC3"}>
            {listData?.is_active === true ? "Active" : "Pending"}
            {listData?.is_active === false && (
              <Button
                backgroundColor={"#737373"}
                color={"#FFFFFF"}
                borderRadius={"lg"}
                onClick={() => resendInvite(listData?.email)}
                className="invite-btn button-wrap"
              >
                Resend Invite
              </Button>
            )}
            {listData?.is_active === true && (
              <Button
                backgroundColor={"rgba(86, 170, 195, 0.2)"}
                borderRadius={"lg"}
                color={"#56AAC3"}
                onClick={() => resetPassword(listData?.email)}
                className="reset-btn button-wrap"
              >
                Reset Password
              </Button>
            )}
          </Text>
        </Td>
        <Td>{dayjs(listData?.date_joined).format("MM/DD/YY")}</Td>
        <Td>
          <CgTrashEmpty onClick={() => deleteUser()} />
        </Td>
      </Tr>
      <Modal
        isOpen={isEditUserModalOpen}
        isCentered
        onClose={onEditUserModalClose}
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <EditUser
              data={listData}
              showRole={showRole}
              setEditUserModalOpen={setEditUserModalOpen}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        id={"delete_modal"}
        isCentered
        onClose={onDeleteModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{"Delete User"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody boxShadow={"none !important"}>
            {"Are you sure you want to delete this ?"}
            {deleteMsg && (
              <Alert status="error" mb={2}>
                <AlertIcon />
                {deleteMsg}
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="red"
                onClick={onDeleteConfirm}
                isLoading={deleteUserMutation.isLoading}
              >
                {"Delete"}
              </Button>

              <Button variant="outline" onClick={onDeleteModalClose}>
                {"Cancel"}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserListItem;
