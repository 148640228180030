import PageNotFound from "components/errors/PageNotFound";
import routes from "constants/routes";
import { Login } from "pages/auth";
import ForgotPassword from "pages/auth/ForgotPassword";
import ResetPassword from "pages/auth/ResetPassword";
import SetPassword from "pages/auth/SetPassword";
import EditOrganization from "pages/dashboard/organizations/EditOrganization";
import OrganizationList from "pages/dashboard/organizations/OrganizationList";
import Setting from "pages/dashboard/settings/Setting";
import CreateUser from "pages/dashboard/users/CreateUser";
import EditUser from "pages/dashboard/users/EditUser";
import UserList from "pages/dashboard/users/UserList";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthenticateRoute from "./AuthenticateRoute";

// Top level application router.

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.auth.login} element={<Login />} />
        <Route path={routes.auth.forgotPassword} element={<ForgotPassword />} />
        <Route path={routes.auth.setPassword} element={<SetPassword />} />
        <Route path={routes.auth.resetPassword} element={<ResetPassword />} />

        <Route path={routes.dashboard} element={<AuthenticateRoute />}>
          {/** Settings */}
          <Route path={routes.settings.index} element={<Setting />} />
          {/** End Settings */}

          {/** Users */}
          <Route path={routes.users.list} element={<UserList />} />
          <Route path={routes.users.create} element={<CreateUser />} />
          <Route path={routes.users.edit} element={<EditUser />} />
          {/** End Users */}

          {/** Organizations */}
          <Route
            path={routes.organization.list}
            element={<OrganizationList />}
          />
          <Route
            path={routes.organization.edit}
            element={<EditOrganization />}
          />
          {/** End Organizations */}

          <Route path={routes.reports.index} element={<UserList />} />
        </Route>
        <Route path={routes.remaining} element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
