/**
 * Component to show result count for a table
 * For eg: Showing 1-15 out of 200
 *
 * Handles loading and no result found as well
 *
 */

import { Text } from "@chakra-ui/react";
import React from "react";

export interface ResultCountDisplayProps {
  isLoading: boolean;
  totalCount: number; // total number of items
  from: number; // number of items present in a page
  to: number;
}

const ResultCountDisplay: React.FC<ResultCountDisplayProps> = (props) => {
  const { isLoading, totalCount, from, to } = props;

  return (
    <Text as="span" color={"#4D4843"} minW="24" mt={"1.5"}>
      {isLoading && `Loading...`}
      {!isLoading && totalCount > 0 && (
        <>
          {from} - {to} of {totalCount}
        </>
      )}
      {!isLoading && from < 1 && "No Result Found."}
    </Text>
  );
};

export default ResultCountDisplay;
