import {
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
} from "@chakra-ui/react";
import OrganizationResource from "api/organization";
import Pagination from "components/common/Pagination";
import TableHeaderComponent from "components/common/TableHeaderComponent";
import TableSkeletonLoader from "components/common/TableSkeletonLoader";
import OrganizationListItem from "components/organization/OrganizationListItem";
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from "constants/common";
import { organizationDataList, organizationListColumns } from "constants/data";
import { FilterParams } from "interface/common/filterParam";
import { OrganizationListSchema } from "interface/organization/organizationListItem";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSearch } from "react-icons/bi";
import { useQuery } from "react-query";
import { getStartingSerialNumber } from "utils";
import CreateOrganization from "./CreateOrganization";

const OrganizationList: React.FC = () => {
  const api = new OrganizationResource();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: "",
    sort_by: "",
  });

  const [organizationList, setOrganizationList] =
    useState<any>(organizationDataList);

  const { isLoading: isListLoading } = useQuery(
    [
      "organizationList",
      {
        page: filterParams.currentPage,
        page_size: filterParams.pageSize,
        keyword: filterParams.keyword,
        sort_by: filterParams.sort_by,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        page_size: filterParams.pageSize,
        has_loggedin_user: false,
      };
      if (filterParams.keyword !== "")
        queryParams.keyword = filterParams.keyword;

      if (filterParams.sort_by !== "")
        queryParams.sort_by = filterParams.sort_by;

      const response = await api.list(queryParams);
      setOrganizationList(response?.data);

      return response?.data?.data;
    }
  );

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      is_approved: data.is_approved || 0,
      search: data.name,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  return (
    <>
      <Stack direction="column" spacing="4" className="organization--wrapper">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md" color={"#23282C"} className="header-text">
              Organizations
            </Heading>
          </Stack>

          <Stack direction="row">
            <Button
              size="sm"
              colorScheme="primary"
              backgroundColor={"#FFFFFF"}
              border={"2px"}
              borderColor={"#616A72"}
              color={"#383861"}
              onClick={() => setDeleteModalOpen(true)}
              className="add-org-btn"
            >
              + Add Organization
            </Button>
          </Stack>
        </Flex>
        <Flex justify={"space-between"}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <InputGroup>
              <InputLeftElement
                children={<Icon color="#737373" w="4" h="4" as={BiSearch} />}
              />
              <Input
                w={[96, 60, 60, 96]}
                type="text"
                placeholder="Search"
                {...methods.register("search")}
                className="search-wrap"
                onBlur={(e: any) => {
                  setFilterParams((prevState: any) => ({
                    ...prevState,
                    keyword: e.target.value,
                  }));
                }}
              />
            </InputGroup>
          </form>
          <Pagination
            dataList={organizationList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        </Flex>

        <Stack bg="white" shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <TableHeaderComponent
                setFilterParams={setFilterParams}
                columns={organizationListColumns}
              />
              <Tbody>
                {!isListLoading &&
                  organizationList?.data?.map(
                    (listData: OrganizationListSchema, index: number) => (
                      <OrganizationListItem
                        listData={listData}
                        key={listData.id}
                        index={startingSN + index}
                      />
                    )
                  )}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={4} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <Modal
        isOpen={isDeleteModalOpen}
        isCentered
        onClose={onDeleteModalClose}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <CreateOrganization setDeleteModalOpen={setDeleteModalOpen} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrganizationList;
